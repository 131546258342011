import React from 'react';

import { SlotTypes } from 'constants/fulfilmentTypes';
import Alert from '@johnlewispartnership/wtr-ingredients/ingredients/Alert';
import { getSlotType } from 'redux/modules/customer-slot/selectors';
import {
  getCollectionMinimumOrderValueAmount,
  getDeliveryMinimumOrderValueAmount,
} from 'redux/modules/trolley/selectors/get-totals';
import { useSelector } from 'react-redux';
import { isNumber } from 'utils/is-number';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';

export interface MinimumSpendNotMetMessageProps {
  isAlert?: boolean;
}

const MinimumSpendNotMetMessage = ({ isAlert = false }: MinimumSpendNotMetMessageProps) => {
  const slotType: SlotTypes = useSelector(getSlotType);
  const collectionMinimumOrderValue: number = useSelector(getCollectionMinimumOrderValueAmount);
  const deliveryMinimumOrderValue: number = useSelector(getDeliveryMinimumOrderValueAmount);

  if (slotType === SlotTypes.ENTERTAINING_COLLECTION) return null;

  const isMinimumSpendKnown =
    isNumber(collectionMinimumOrderValue) && isNumber(deliveryMinimumOrderValue);

  const noSlotBookedMinimumOrderValue =
    isMinimumSpendKnown && Math.min(collectionMinimumOrderValue, deliveryMinimumOrderValue);

  let minimumSpendMessage = 'To check out, please meet our minimum spend';

  if (slotType === SlotTypes.DELIVERY && deliveryMinimumOrderValue) {
    minimumSpendMessage = `To check out, please meet our £${deliveryMinimumOrderValue} minimum spend (excluding delivery charge)`;
  } else if (slotType === SlotTypes.GROCERY_COLLECTION && collectionMinimumOrderValue) {
    minimumSpendMessage = `To check out, please meet our £${collectionMinimumOrderValue} minimum spend`;
  } else if (slotType === SlotTypes.UNDEFINED && noSlotBookedMinimumOrderValue) {
    minimumSpendMessage = `To check out, please meet our £${noSlotBookedMinimumOrderValue} minimum spend (excluding delivery charge if applicable)`;
  }

  return isAlert ? (
    <Alert
      type="warning"
      data-testid="minimum-spend-not-met-message"
      message={minimumSpendMessage}
    />
  ) : (
    <Typography styleAs="paragraphHeading" noMargins>
      {minimumSpendMessage}
    </Typography>
  );
};

export default MinimumSpendNotMetMessage;
