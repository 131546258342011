export type OrderType = 'GROCERIES' | 'ENTERTAINING' | 'GROCERIES_ENTERTAINING';

export const orderTypes = {
  GROCERIES: 'GROCERIES',
  ENTERTAINING: 'ENTERTAINING',
  GROCERIES_ENTERTAINING: 'GROCERIES_ENTERTAINING',
} as const;

interface MatchOrderTypeMapArg<T> {
  onEntertaining: () => T;
  onGroceries: () => T;
  onGroceriesEntertaining: () => T;
}

export function matchOrderType<T>(
  orderType: OrderType | null,
  { onEntertaining, onGroceries, onGroceriesEntertaining }: MatchOrderTypeMapArg<T>,
  orElse: () => T,
): T;
export function matchOrderType<T>(
  orderType: OrderType | null,
  { onEntertaining, onGroceries, onGroceriesEntertaining }: MatchOrderTypeMapArg<T>,
  orElse?: null | (() => T),
): T | null;
export function matchOrderType<T>(
  orderType: OrderType | null,
  { onEntertaining, onGroceries, onGroceriesEntertaining }: MatchOrderTypeMapArg<T>,
  orElse?: null | (() => T),
): T | null {
  // eslint-disable-next-line default-case
  switch (orderType) {
    case orderTypes.ENTERTAINING:
      if (onEntertaining) return onEntertaining();
      break;

    case orderTypes.GROCERIES_ENTERTAINING:
      if (onGroceriesEntertaining) return onGroceriesEntertaining();
      break;

    case orderTypes.GROCERIES:
      if (onGroceries) return onGroceries();
      break;
  }

  return orElse ? orElse() : null;
}
