import { connect } from 'react-redux';
import {
  checkoutConditions,
  checkoutDestination,
  checkoutDisabled,
} from 'redux/modules/checkout/selectors/checkout';
import getLoading from 'redux/modules/trolley/selectors/get-loading';
import {
  canInstantCheckout,
  getInstantCheckoutReason,
} from 'redux/modules/trolley/selectors/can-instant-checkout';
import { confirmInstantCheckout } from 'redux/modules/instant-checkout/actions/instant-checkout-progress-status';
import GoToCheckout from './GoToCheckout';

export default connect(
  state => ({
    destination: checkoutDestination(state),
    conditions: checkoutConditions(state),
    disabled: checkoutDisabled(state),
    trolleyLoading: getLoading(state),
    canInstantCheckout: canInstantCheckout(state),
    getInstantCheckoutReason: getInstantCheckoutReason(state),
  }),
  {
    confirmInstantCheckout,
  },
)(GoToCheckout);
