import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import history from 'utils/history';
import {
  getNextInterstitialsPath,
  isInterstitialLoading,
} from 'redux/modules/interstitials/selectors';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import GoToCheckout from 'components/GoToCheckout';

import styles from 'components/ProceedThroughCheckout/ProceedThroughCheckout.scss';
import { yieldToMain } from 'utils/yield-to-main';

const ProceedThroughCheckout = ({
  fullWidth,
  messagePlacement,
  onInterstitialsPage,
  location,
  handleClick,
  buttonCheckoutId,
  theme,
  useLightTooltip,
}) => {
  const next = useSelector(getNextInterstitialsPath);
  const loading = useSelector(isInterstitialLoading);

  const handleOnClick = async () => {
    handleClick();
    await yieldToMain();
    history.push(next);
  };

  return next || loading ? (
    <div className={styles.proceedThroughCheckout}>
      <Button
        disabled={loading}
        onClick={handleOnClick}
        data-test="interstitials-next"
        data-test-context={onInterstitialsPage ? 'page' : 'header'}
        label={onInterstitialsPage ? 'Next step' : 'Checkout'}
        theme={theme ?? 'finalising'}
        type="button"
        width="fit"
      />
    </div>
  ) : (
    <GoToCheckout
      fullWidth={fullWidth}
      theme={theme}
      messagePlacement={messagePlacement}
      location={location}
      buttonCheckoutId={buttonCheckoutId}
      canShowInstantCheckoutModal={['checkout-amend-order-alert'].includes(buttonCheckoutId)}
      useLightTooltip={useLightTooltip}
    />
  );
};

ProceedThroughCheckout.propTypes = {
  fullWidth: PropTypes.bool,
  messagePlacement: PropTypes.string.isRequired,
  onInterstitialsPage: PropTypes.bool,
  location: PropTypes.string,
  handleClick: PropTypes.func,
  buttonCheckoutId: PropTypes.string,
  theme: PropTypes.string,
  useLightTooltip: PropTypes.bool,
};

ProceedThroughCheckout.defaultProps = {
  fullWidth: false,
  onInterstitialsPage: false,
  location: '',
  handleClick: () => {},
  buttonCheckoutId: undefined,
  theme: undefined,
  useLightTooltip: false,
};

export default ProceedThroughCheckout;
