import { confirmCancelAmendOrderModal } from 'constants/modals';
import { ModalCloseOptions } from 'constants/modalCloseOptions';

import { cancelAmendOrder } from 'redux/modules/amend-order/actions/cancel-amend-order';

import { openModal } from 'redux/modules/common-modal/actions';

export const confirmCancelAmendOrder = () => dispatch => {
  dispatch(
    openModal({
      ...confirmCancelAmendOrderModal,
      onCloseCallback: modalCloseOption => {
        if (modalCloseOption === ModalCloseOptions.OK) {
          dispatch(cancelAmendOrder());
        }
      },
    }),
  );
};
