import {
  CONFIRM_INSTANT_CHECKOUT,
  STARTED_INSTANT_CHECKOUT,
  FINISHED_INSTANT_CHECKOUT,
} from './types';

export const confirmInstantCheckout = () => ({
  type: CONFIRM_INSTANT_CHECKOUT,
});

export const startedInstantCheckout = () => ({
  type: STARTED_INSTANT_CHECKOUT,
});

export const finishedInstantCheckout = () => ({
  type: FINISHED_INSTANT_CHECKOUT,
});
