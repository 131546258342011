import { SlotTypes } from 'constants/fulfilmentTypes';
import { createSelector } from 'reselect';
import { orderTypes } from 'utils/checkout/order-type';
import { getContainsEntertainingLines } from './get-order-contains-entertaining-lines';
import { getSlotType } from './get-order-slot-selectors';

export const getOrderType = createSelector(
  getSlotType,
  getContainsEntertainingLines,
  /**
   * @returns {(null|import('utils/checkout/order-type').OrderType)}
   */
  (slotType, containsEntertainingLines) => {
    if (!slotType || slotType === SlotTypes.UNDEFINED) {
      return null;
    }

    if (slotType === SlotTypes.ENTERTAINING_COLLECTION) {
      return orderTypes.ENTERTAINING;
    }

    if (containsEntertainingLines) {
      return orderTypes.GROCERIES_ENTERTAINING;
    }

    return orderTypes.GROCERIES;
  },
);
