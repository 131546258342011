import { connect } from 'react-redux';

import { currentlyAmendingOrder } from 'redux/modules/amend-order/selectors';
import amendingOrder from 'redux/modules/trolley/selectors/amending-order';
import { togglePinBar } from 'redux/modules/page/actions';

import SitePinBar from './SitePinBar';

export default connect(
  state => ({
    isOpen: state.page.isPinBarOpen,
    loading: currentlyAmendingOrder(state),
    visible: amendingOrder(state),
  }),
  {
    onToggle: togglePinBar,
  },
)(SitePinBar);
