import { connect } from 'react-redux';

import { getFulfilmentTypeForBookedSlot } from 'redux/modules/slot-booking/selectors/get-fulfilment-type';

import { confirmCancelAmendOrder } from 'redux/modules/amend-order/actions/confirm-cancel-amend-order';
import { getOrderType } from 'redux/modules/order/selectors/get-order-type';
import AmendOrderAlert from './AmendOrderAlert';

export default connect(
  state => {
    const { amendOrderCutOff, slotDate, slotEndTime, slotStartTime } = state.customerSlot;
    return {
      amendOrderCutOff,
      fulfilmentType: getFulfilmentTypeForBookedSlot(state),
      slotDate,
      slotEndTime,
      slotStartTime,
      orderType: getOrderType(state),
    };
  },
  {
    onCancelChanges: confirmCancelAmendOrder,
  },
)(AmendOrderAlert);
