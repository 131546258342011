import Alert from '@johnlewispartnership/wtr-ingredients/ingredients/Alert';
import Button, { ButtonProps } from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { dataLayer } from 'analytics/data-layer';
import classnames from 'classnames';
import urls from 'constants/urls';
import React from 'react';
import { Tooltip } from '@johnlewispartnership/wtr-ingredients/ingredients/Tooltip';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { useDispatch } from 'react-redux';
import { CHECKOUT_CONDITIONS } from 'redux/modules/checkout/selectors/checkout';
import { UPDATE_INSTANT_CHECKOUT_LOCATION } from 'redux/modules/trolley/actions/types';
import { goToInternalOrExternalLocation } from 'utils/go-to-internal-or-external-location';
import { pushEnterCheckoutEvent } from 'utils/gtm';
import styles from './GoToCheckout.scss';
import MinimumSpendNotMetMessage from './MinimumSpendNotMet/MinimumSpendNotMetMessage';

type MessagePlacement = 'inline' | 'bottomPopover' | 'topPopover';
type GetInstantCheckoutReason = 'not_amending' | 'not_allowed' | 'threshold_exceeded' | 'allowed';

export interface GoToCheckoutProps {
  conditions?: string[];
  buttonCheckoutId?: string | undefined;
  disabled?: boolean;
  fullWidth?: boolean;
  hideWhenDisabled?: boolean;
  destination: string;
  messagePlacement?: MessagePlacement;
  location?: string;
  theme?: ButtonProps['theme'];
  canInstantCheckout: boolean;
  trolleyLoading: boolean;
  confirmInstantCheckout: () => void;
  canShowInstantCheckoutModal: boolean;
  getInstantCheckoutReason?: GetInstantCheckoutReason;
  useLightTooltip?: boolean;
}
const GoToCheckout = ({
  conditions = [],
  buttonCheckoutId = undefined,
  disabled = false,
  fullWidth = false,
  hideWhenDisabled,
  destination = '',
  messagePlacement = 'inline',
  location,
  theme = 'finalising',
  canInstantCheckout = false,
  trolleyLoading = false,
  confirmInstantCheckout,
  canShowInstantCheckoutModal = false,
  getInstantCheckoutReason,
  useLightTooltip = false,
}: GoToCheckoutProps) => {
  const dispatch = useDispatch();

  if (disabled && hideWhenDisabled) return null;

  const { HAS_HARD_CONFLICTS, MINIMUM_SPEND_NOT_MET } = CHECKOUT_CONDITIONS;
  const inline = messagePlacement && messagePlacement.includes('inline');
  const buttonId = buttonCheckoutId ?? 'site-header-checkout';

  const inlineMessage = (
    <div id={`${buttonId}-disabled-msg`} className={styles.inlineMsg}>
      {conditions.find(condition => condition === MINIMUM_SPEND_NOT_MET) && (
        <MinimumSpendNotMetMessage isAlert />
      )}
      {conditions.find(condition => condition === HAS_HARD_CONFLICTS) && (
        <Alert
          type="warning"
          title="Unavailable items"
          message="Please remove all unavailable items from your trolley"
        />
      )}
    </div>
  );

  const goToCheckout = () => {
    dispatch({ location, type: UPDATE_INSTANT_CHECKOUT_LOCATION });

    if (canShowInstantCheckoutModal) {
      // prevent sending the GA event in interstitial pages
      dataLayer.push({
        event: 'instant_checkout_trigger',
        instant_checkout: {
          available: canInstantCheckout,
          availability_reason: getInstantCheckoutReason,
        },
      });
    }

    if (canInstantCheckout && canShowInstantCheckoutModal) {
      confirmInstantCheckout();
    } else {
      goToInternalOrExternalLocation(destination);
    }

    if (destination === urls.interstitials) pushEnterCheckoutEvent(location);
  };

  const tooltipMessage = (
    <div id="minimumOrder">
      {conditions.find(condition => condition === MINIMUM_SPEND_NOT_MET) && (
        <MinimumSpendNotMetMessage />
      )}
      {conditions.find(condition => condition === HAS_HARD_CONFLICTS) && (
        <Typography styleAs="paragraphHeading" noMargins>
          You have unavailable items in your trolley, please make the required changes in order to
          checkout
        </Typography>
      )}
    </div>
  );

  const checkoutButton = (
    <div className={styles.buttonOverlayTrigger}>
      <Button
        waiting={trolleyLoading}
        aria-describedby={`${buttonId}-disabled-msg`}
        aria-label="Checkout"
        data-testid="go-to-checkout-button"
        disabled={disabled}
        id={buttonId}
        label="Checkout"
        onClick={goToCheckout}
        theme={theme}
        type="submit"
        width="full"
      />
    </div>
  );

  const checkoutOrTooltip =
    messagePlacement.includes('Popover') && disabled ? (
      <Tooltip
        content={tooltipMessage}
        placement={messagePlacement.includes('top') ? 'top' : 'bottom'}
        className={classnames(styles.disabledTooltip, { [styles.light]: useLightTooltip })}
        disableFocusListener
      >
        {checkoutButton}
      </Tooltip>
    ) : (
      checkoutButton
    );

  return (
    <div className={classnames({ [styles.checkout]: !inline, [styles.fullSize]: fullWidth })}>
      {checkoutOrTooltip}
      {inline && disabled && inlineMessage}
    </div>
  );
};

export default GoToCheckout;
