import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AmendOrderAlert from 'components/AmendOrderAlert';

import Spinner from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';

import styles from './SitePinBar.scss';

const SitePinBar = ({ isOpen, loading, onToggle, visible }) => {
  return (
    <Fragment>
      {loading && (
        <div className={styles.loading} data-test="amend-order-loading">
          <Spinner isActive pageSpinner />
        </div>
      )}
      {visible && (
        <aside
          className={classNames(styles.pinBar, {
            [styles.open]: isOpen,
            [styles.closed]: !isOpen,
          })}
          id="sitePinBar"
          data-testid="site-pin-bar"
        >
          <AmendOrderAlert isOpen={isOpen} onToggle={onToggle} />
        </aside>
      )}
    </Fragment>
  );
};

SitePinBar.displayName = 'SitePinBar';

SitePinBar.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  onToggle: PropTypes.func,
  visible: PropTypes.bool,
};

SitePinBar.defaultProps = {
  isOpen: false,
  loading: false,
  onToggle: () => {},
  visible: false,
};

export default SitePinBar;
